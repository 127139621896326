import config from "../config";
const getAllJobs = async (horizon) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${horizon}/alljobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllJobsNoDate = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/alljobsnodate`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserAllJobs = async (horizon,user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${horizon}/alljobs/${user}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      Accept: "application/json",
       
      method: 'POST',
      body: JSON.stringify(options),
      headers: {credentials: "include",
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserAllJobsNoDate = async (user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/alljobsnodate/${user}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const stopActiveTask = async (id,t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${id}/${t}/taskstop`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserJobTimers = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${id}/running`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const jobTimerExists = async (u,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${u}/timerexists/${j}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskTimerExists = async (u,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${u}/timerexists/${j}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const jobTimerCreate = async (u,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${u}/${j}/create`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskTimerCreate = async (u,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${u}/${j}/create`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const jobTimerStart = async (u,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${u}/${j}/start`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskTimerStart = async (u,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${u}/${j}/start`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const jobTimerStop = async (u,j,t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${u}/${j}/${t}/stop`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskTimerStop = async (u,j,t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${u}/${j}/${t}/stop`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const jobTimerStopAll = async (u) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${u}/stopall`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskTimerStopAll = async (u) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${u}/stopall`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskTimerStopAllTask = async (t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${t}/stopalltask`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const stopAllJobTaskTimers = async (j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${j}/stopalljobtimers`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const stopAllTaskTimers = async (a) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${a}/stopalltasktimers`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserTaskTimers = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${id}/running`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllTasks = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/task/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllTasksUser = async (lookback,user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/lookbacktasks/${lookback}/${user}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllTasksLookback = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/lookbacktasks/${lookback}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserTaskTimer = async (user,task) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${user}/${task}/usertaskget`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllWorkPackages = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/workPackage/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getJobSubPackages = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/subPackage/workpackage/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getJobWorkPackages = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobWorkPackage/job/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserHorizons = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/userHorizon/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getOtherWorkPackages = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/workPackage/${id}/indexnot`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getClosedJobs = async(lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${lookback}/completejobs/`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserClosedJobs = async(lookback,user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${lookback}/completejobs/${user}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getNeglectedJobs = async() => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/neglectedjobs/`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getNoDateJobs = async() => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/nodatejobs/`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getOpenJobs = async(horizon) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${horizon}/incompletejobs/`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserOpenJobs = async(horizon,user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${horizon}/incompletejobs/${user}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getSingleJob = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getSingleTask = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/task/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyTask = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/task/${id}/destroy`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const taskExists = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${id}/taskexists`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};



const getSingleActiveTask = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${id}/showsingle`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getSingleWorkPackage = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/workPackage/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getSubPackages = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/subPackage/workpackage/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getPackageTasks = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/workPackageTask/workpackage/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getTemplateJobs = async() => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/templatejobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsBehind = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${lookback}/behindjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getUserJobsBehind = async (user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/behindjobs/${user}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTasksBehind = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/allbehindtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getNoDateTasks = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/nodatetasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTasksBehindUser = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/behindtasks/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getSingleJobRecurrence = async(id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobRecurrence/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTasksCompleteUser = async (lookback,id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/lookbackcompletedtasks/${lookback}/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTasksComplete = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/lookbackcompletedtasks/${lookback}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTasksAllUser = async (lookback,id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/lookbackalltasks/${lookback}/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTasksAll = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/lookbackalltasks/${lookback}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsMonthAll = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/monthjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsMonthComplete = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/monthcompletedjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsMonthCancelled = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/monthcancelledjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsLookbackAll = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/lookbackjobs/${lookback}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsLookbackComplete = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/lookbackcompletedjobs/${lookback}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobsLookbackCancelled = async (lookback) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/lookbackcancelledjobs/${lookback}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getJobsCancelled = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/cancelledjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getHorizon = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/userHorizon/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getTaskReferences = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskReference/${id}/showtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobReferences = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobReference/${id}/showjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};


const getJobHistory = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobHistory/${id}/showjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getTaskHistory = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskHistory/task/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getTasksForJob = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${id}/showjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getTasksHorizon = async (horizon) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${horizon}/showtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getCompletedTasksHorizon = async (horizon) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${horizon}/showcompletedtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getCompletedTasksUserHorizon = async (horizon,user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${horizon}/${user}/showcompletedtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getBehindTasksHorizon = async (horizon) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${horizon}/showbehindtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getBehindTasksUserHorizon = async (horizon,user) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${horizon}/${user}/showbehindtasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getTasksUserHorizon = async (horizon,u) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${horizon}/${u}/showtasksuser`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const saveJobHistory = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobHistory/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveTaskHistory = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/taskHistory/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveJobRecurrence = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobRecurrence/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveJobReference = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobReference/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveTaskReference = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/taskReference/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const savePackageTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/workPackageTask/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveSubPackage = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/subPackage/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveJobTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobTask/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

  const multiAllocateTask = async (obj) => {
    const options = {
      base: "https://jobs-tasks-service",
      params: false,
      payload: obj,
      endpoint: `/api/jobTask/multiallocated`,
      method: "POST"
    }
  
    const data = await fetch(
      `${config.appURL}/api/proxy`,
      {
        
        method: 'POST',
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
       
        },
      }
    );

  const res = await data.json();

  return res;
};

const singleAllocateTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobTask/singleallocated/${obj.id}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

const res = await data.json();

return res;
};


const getHistoryTypes = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/historyType/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getJobTimerSum = async (j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${j}/jobsum`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getJobTaskTimerSum = async (j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${j}/jobtasksum`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getTaskTimerSum = async (t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/taskTimer/${t}/tasksum`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getCustomerTypes = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/customerType/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const closeJob = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/${obj.id}/close`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editJob = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/${obj.id}/storecore`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/task/${obj.task_id}/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editActiveTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/${obj.id}/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const jobTasksClose = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/${obj.job_id}/jobtasksclose`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const jobTasksHistoryClose = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/taskHistory/${obj.job_id}/storejobclose`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editActiveTaskCore = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/${obj.id}/editcore`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editActiveTaskCoreKanban = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/${obj.id}/editcorekanban`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const closeActiveTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/${obj.id}/closetask`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const changeTaskKanban = async (id,board) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${id}/changeboard/${board}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const changeTaskKanbanUser = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/changeboarduser`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const changeJobKanban = async (id,board) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${id}/changeboard/${board}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const changeJobKanbanUser = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/changeboarduser`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const saveActiveTaskCore = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/activeTask/storecore`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const replicateJob = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${id}/replicate`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJob = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobYearly = async (obj,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/storeyearly/${j}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobMonthly = async (obj,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/storemonthly/${j}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobWeekly = async (obj,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/storeweekly/${j}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobDaily = async (obj,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/storedaily/${j}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobBespoke = async (obj,x,j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/${x}/storebespoke/${j}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createTask = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/task/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editWorkPackage = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/workPackage/${obj.id}/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editUserHorizon = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/userHorizon/${obj.user}/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createWorkPackage = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/workPackage/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobWorkPackage = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobWorkPackage/createnew`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getJobLists = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobList/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getJobList = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobList/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createJobList = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobList/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getListItems = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobListItem/${id}/list`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const replicateJobTasks = async (j,n) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${j}/jobreplicate/${n}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const duplicateJobWorkPackage = async (j,n) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobWorkPackage/${j}/replicate/${n}`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createListItem = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobListItem/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createMultiListItemSupplier = async (obj,l) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobListItem/${l}/multistoresupplier`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};


const createMultiListItemActiveABC = async (obj,l) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/jobListItem/${l}/multistoreactiveabc`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const destroyJobListItems = async (l) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobListItem/${l}/destroylist`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyJobListItem = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobListItem/${id}/destroy`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyJobList = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobList/${id}/destroy`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyActiveTask = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${id}/destroy`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyJobTask = async (j,t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTask/${j}/${t}/destroyjobtask`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyWPTask = async (wp,t) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/workPackageTask/${wp}/${t}/destroywptask`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const destroyWorkPackage = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobWorkPackage/${id}/destroy`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllKanbans = async () => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/board/index`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getAllUserKanbans = async (u) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/board/${u}/indexuser`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getSingleKanban = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/board/${id}/show`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getKanbanUsers = async (b) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/boardUser/${b}/indexboard`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const createKanbanUser = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/boardUser/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const removeKanbanUser = async (id) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/boardUser/${id}/destroy`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const removeKanbanUser2 = async (u,b) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/boardUser/${u}/destroyuserboard/${b}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const newJobClose = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/job/${obj.id}/newclose`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const editKanban = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/board/${obj.id}/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createKanban = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/board/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createUserHorizon = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/userHorizon/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const createKanbanElement = async (obj) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: obj,
    endpoint: `/api/boardElement/store`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();

  return res;
};

const getKanbanElements = async (b) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/boardElement/${b}/indexboard`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getKanbanTasks = async (b) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/activeTask/${b}/kanbantasks`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const getKanbanJobs = async (b) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/job/${b}/kanbanjobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

const stopAllJobTimers = async (j) => {
  const options = {
    base: "https://jobs-tasks-service",
    params: false,
    payload: false,
    endpoint: `/api/jobTimer/${j}/stopalljobs`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
       
      },
    }
  );

  const res = await data.json();
  res['success'] = data.ok;
  res['statusCode'] = data.status;
  
  return res;
};

export {
  getAllJobs,
  getAllJobsNoDate,
  getUserAllJobs,
  getUserAllJobsNoDate,
  getClosedJobs,
  getUserClosedJobs,
  getNeglectedJobs,
  getNoDateJobs,
  getOpenJobs,
  getUserOpenJobs,
  getTemplateJobs,
  getJobsBehind,
  getUserJobsBehind,
  getHorizon,
  getJobsMonthAll,
  getJobsMonthComplete,
  getJobsMonthCancelled,
  getJobsLookbackAll,
  getJobsLookbackComplete,
  getJobsLookbackCancelled,
  getJobsCancelled,
  getSingleJob,
  getJobReferences,
  getTaskReferences,
  getJobHistory,
  getTaskHistory,
  getTasksForJob,
  saveJobHistory,
  saveTaskHistory,
  getAllTasks,
  getAllTasksUser,
  getAllTasksLookback,
  saveJobTask,
  saveActiveTaskCore,
  getHistoryTypes,
  getSingleJobRecurrence,
  saveJobReference,
  saveTaskReference,
  getCustomerTypes,
  closeJob,
  editJob,
  createJob,
  saveJobRecurrence,
  getAllWorkPackages,
  getJobWorkPackages,
  getJobSubPackages,
  getOtherWorkPackages,
  getSingleWorkPackage,
  editWorkPackage,
  createWorkPackage,
  getSubPackages,
  getPackageTasks,
  savePackageTask,
  saveSubPackage,
  getTasksBehind,
  getNoDateTasks,
  getTasksBehindUser,
  getTasksCompleteUser,
  getTasksComplete,
  getTasksAllUser,
  getTasksAll,
  getUserHorizons,
  editUserHorizon,
  getUserTaskTimer,
  createTask,
  editTask,
  getSingleTask,
  getSingleActiveTask,
  editActiveTask,
  editActiveTaskCore,
  editActiveTaskCoreKanban,
  closeActiveTask,
  changeTaskKanban,
  changeTaskKanbanUser,
  changeJobKanban,
  changeJobKanbanUser,
  getTasksUserHorizon,
  getTasksHorizon,
  getCompletedTasksHorizon,
  getCompletedTasksUserHorizon,
  getBehindTasksHorizon,
  getBehindTasksUserHorizon,
  createJobWorkPackage,
  getUserJobTimers,
  getUserTaskTimers,
  jobTimerExists,
  jobTimerCreate,
  jobTimerStart,
  jobTimerStop,
  jobTimerStopAll,
  taskTimerExists,
  taskTimerCreate,
  taskTimerStart,
  taskTimerStop,
  taskTimerStopAll,
  taskTimerStopAllTask,
  getJobLists,
  getJobList,
  createJobList,
  createListItem,
  getListItems,
  createMultiListItemSupplier,
  createMultiListItemActiveABC,
  destroyJobList,
  destroyJobListItems,
  destroyJobListItem,
  createJobYearly,
  createJobMonthly,
  createJobWeekly,
  createJobDaily,
  createJobBespoke,
  getAllKanbans,
  getAllUserKanbans,
  getSingleKanban,
  getKanbanUsers,
  createKanbanUser,
  removeKanbanUser,
  removeKanbanUser2,
  editKanban,
  createKanban,
  createKanbanElement,
  getKanbanElements,
  getKanbanTasks,
  getKanbanJobs,
  stopActiveTask,
  replicateJob,
  createUserHorizon,
  multiAllocateTask,
  singleAllocateTask,
  stopAllJobTimers,
  newJobClose,
  taskExists,
  destroyTask,
  stopAllJobTaskTimers,
  stopAllTaskTimers,
  jobTasksClose,
  getJobTaskTimerSum,
  getJobTimerSum,
  getTaskTimerSum,
  jobTasksHistoryClose,
  duplicateJobWorkPackage,
  replicateJobTasks,
  destroyActiveTask,
  destroyJobTask,
  destroyWPTask,
  destroyWorkPackage,
};
